import { h } from 'preact';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faEdit,
    faEnvelope,
    faLock,
    faPen,
    faPlusCircle,
    faRedo,
    faShieldAlt,
    faSignOutAlt,
    faSpinner,
    faTrash,
 } from '@fortawesome/free-solid-svg-icons'

import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { checkHasProperties, HasIsLeft, HasSize, HasTextColor } from 'components/style';
import * as style from 'components/style.scss';
 
library.add(faRedo, faSpinner, faSignOutAlt, faEdit, faPen, faTrash, faPlusCircle, faLock, faEnvelope, faShieldAlt);

export const Icons = {
    redo: 'redo' as IconName,
    spinner: 'spinner' as IconName,
    signOut: 'sign-out-alt' as IconName,
    edit: 'edit' as IconName,
    pen: 'pen' as IconName,
    trash: 'trash' as IconName,
    plus: 'plus-circle' as IconName,
    lock: 'lock' as IconName,
    envelope: 'envelope' as IconName,
    shieldAlt: 'shield-alt' as IconName,
}

interface IconProps extends HasTextColor, HasSize, HasIsLeft {
    icon: IconName;
    spin?: boolean;
    pulse?: boolean;
    border?: boolean;
    iconSize?: "xs" | "lg" | "sm" | "1x" | "2x" | "3x" | "4x" | "5x" | "6x" | "7x" | "8x" | "9x" | "10x" | undefined ;
}

export const Icon: preact.FunctionalComponent<IconProps> = ( props ) =>  {
    return (
        <span class={checkHasProperties(props, [style.icon])}>
            {/* 
            // @ts-ignore */}
            <FontAwesomeIcon icon={props.icon} size={props.iconSize} spin={props.spin} pulse={props.pulse} border={props.border}/> 
        </span>
    );
}