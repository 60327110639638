import storage from 'redux-persist/lib/storage';

import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { reset } from 'redux-form';
import { resetApp, store as reduxStore } from 'utils/redux/store';
import { show as toastShow } from 'utils/toast';

const AUTH_LOGIN = 'AUTH_LOGIN';
const AUTH_LOGOUT = 'AUTH_LOGOUT';
const AUTH_LOGGEDIN = 'AUTH_LOGGEDIN';
const AUTH_ERROR = 'AUTH_ERROR';
const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
const AUTH_DO_CHANGE_PASSWORD = 'AUTH_DO_CHANGE_PASSWORD';
const AUTH_REFRESH = 'AUTH_REFRESH';
const AUTH_RESET_PASSWORD_CODE = 'AUTH_RESET_PASSWORD_CODE';
const AUTH_RESET_PASSWORD_CODE_SENT = 'AUTH_RESET_PASSWORD_CODE_SENT';
const AUTH_RESET_PASSWORD_CODE_ERROR = 'AUTH_RESET_PASSWORD_CODE_ERROR';
const AUTH_DO_RESET_PASSWORD = 'AUTH_DO_RESET_PASSWORD';
const AUTH_DO_RESET_PASSWORD_DONE = 'AUTH_DO_RESET_PASSWORD_DONE';

const authLogin = (email: string, password: string) => {
    return {
        type: AUTH_LOGIN,
        email,
        password
    };
}

const authLoggedIn = (token: any) => {
    return {
        type: AUTH_LOGGEDIN,
        token,
    };
}

const authError = (error: string) => {
    return {
        type: AUTH_ERROR,
        error,
    }
}

const authLogout = () => {
    return {
        type: AUTH_LOGOUT
    };
}

const authChangePassword = (token: any) => {
    return {
        type: AUTH_CHANGE_PASSWORD,
        token
    };
}

const authDoChangePassword = (password: string) => {
    return {
        type: AUTH_DO_CHANGE_PASSWORD,
        password
    };
}

const authRefresh = () => {
    return {
        type: AUTH_REFRESH,
    };
}

const authResetPasswordCode = (email:string) => {
    return {
        type: AUTH_RESET_PASSWORD_CODE,
        email
    }
}

const authResetPasswordCodeSent = () => {
    return {
        type: AUTH_RESET_PASSWORD_CODE_SENT
    }
}

const authResetPassowordCodeError = () => {
    return {
        type: AUTH_RESET_PASSWORD_CODE_ERROR
    }
}

const authDoResetPassword = (email:string, code:string, password:string) => {
    return {
        type: AUTH_DO_RESET_PASSWORD,
        email,
        code,
        password
    }
}

const authDoResetPasswordDone = () => ({type: AUTH_DO_RESET_PASSWORD_DONE});

const defaultState = { token: null, loggingIn: false, error: null, changePassword: false, refreshing: false, passwordCodeSent: false, passwordCodeSending: false,
                        resettingPassword: false, resettingPasswordDone: false };

const authReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case AUTH_LOGIN:
            Auth.signIn(action.email, action.password)
                .then((user) => {
                    console.log(user);
                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        console.log('Requires new password!');
                        action.asyncDispatch(authChangePassword(user));
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
            return Object.assign({}, defaultState, { loggingIn: true });
        case AUTH_LOGGEDIN:
            return Object.assign({}, defaultState, { token: action.token });
        case AUTH_LOGOUT:
            Auth.signOut();
            action.asyncDispatch(resetApp());
            return defaultState;
        case AUTH_ERROR:
            return Object.assign({}, state, { error: action.error, loggingIn: false });
        case AUTH_CHANGE_PASSWORD:
            action.asyncDispatch(reset('login'));
            return Object.assign({}, defaultState, { token: action.token, changePassword: true });
        case AUTH_DO_CHANGE_PASSWORD:
            Auth.completeNewPassword(
                state.token,           // the Cognito User Object
                action.password,       // the new password
                // OPTIONAL, the required attributes
                {
                }
            )
                .then((data) => {
                    console.log('Change Password success');
                    console.log(data);
                })
                .catch(e => {
                    console.log('Change password error');
                    console.log(e);
                    if (e.code === "InvalidPasswordException") {
                        action.asyncDispatch(authError('Lösenordet måste vara minst 8 tecken'));
                    } else {
                        action.asyncDispatch(authError('Okänt fel.'));
                    }
                });
            return Object.assign({}, state, { loggingIn: true });
        case AUTH_REFRESH:
            return Object.assign({}, state, {refreshing: true});
        case AUTH_RESET_PASSWORD_CODE:
            Auth.forgotPassword(action.email)
                .then(data => {
                    console.log(data);
                    action.asyncDispatch(authResetPasswordCodeSent());
                })
                .catch(err => {
                    console.log(err);
                    action.asyncDispatch(authError('Kunde inte skicka återställningskod, kontrollera uppgifterna.'));
                    action.asyncDispatch(authResetPassowordCodeError());
                });
            return Object.assign({}, defaultState, {passwordCodeSending: true});
        case AUTH_RESET_PASSWORD_CODE_SENT:
            return Object.assign({}, defaultState, {passwordCodeSent: true});
        case AUTH_RESET_PASSWORD_CODE_ERROR:
            return Object.assign({}, state, {passwordCodeSending: false});
        case AUTH_DO_RESET_PASSWORD:
            Auth.forgotPasswordSubmit(action.email, action.code, action.password)
                .then(data => {
                    action.asyncDispatch(authDoResetPasswordDone());
                })
                .catch(err => {
                    console.log(err)
                });
            return Object.assign({}, state, {resettingPassword: true});
        case AUTH_DO_RESET_PASSWORD_DONE:
            return Object.assign({}, defaultState, {resettingPasswordDone: true});
        }
        
    return state;
}

const authIsLoggedIn = (auth: { token: string, changePassword: boolean }) => {
    return auth.token !== null && !auth.changePassword;
}

const persistConfig = {
    key: 'auth',
    storage,
    whitelist: ['token']
}

// @ts-ignore
// authReducer = persistReducer(persistConfig, authReducer);


const listener = (data: any) => {
    // console.log(data);
    switch (data.payload.event) {
        case 'signIn':
            reduxStore.dispatch(authLoggedIn(data.payload.data));
            break;
        case 'signUp':
            break;
        case 'signOut':
            console.log('user signed out');
            break;
        case 'signIn_failure':
            if (data.payload.data.code === 'NetworkError') {
                toastShow({type: 'danger', title: 'Nätverksfel', message: 'Lyckades inte ansluta, kontrollera din uppkoppling.', duration: 5000});
                reduxStore.dispatch(authError(''));
            } else {
                reduxStore.dispatch(authError('Kunde inte logga in, kontrollera email och lösenord.'));
            }
            break;
        case 'configured':
            Auth.currentAuthenticatedUser({
                bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
            }).then(user => {
                reduxStore.dispatch(authLoggedIn(user));
            }).catch(() => {
                reduxStore.dispatch(authLogout());
            });
            reduxStore.dispatch(authRefresh());

            console.log('the Auth module is configured');
    }
}

Hub.listen('auth', listener);

export {
    authReducer,
    authLogin,
    authLogout,
    authLoggedIn,
    authError,
    authIsLoggedIn,
    authDoChangePassword,
    authResetPasswordCode,
    authDoResetPassword
};
