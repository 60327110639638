import { checkHasProperties, HasClass, HasTextAlign, HasTextCentered } from 'components/style';
import * as style from 'components/style.scss';
import { h, VNode } from 'preact';

/* Section */
interface SectionProps extends HasClass {
    children?: any | any[];
}

const Section : preact.FunctionalComponent<SectionProps> = ( props ) => {
    return (
        <section class={checkHasProperties(props, style.section)}>
            { props.children }
        </section>
    )
}


/* Container */
interface ContainerProps extends HasTextAlign, HasClass {
    isFluid?: boolean;
    children?: any| any[];
}

const Container : preact.FunctionalComponent<ContainerProps> = ( props ) => {
    return (
        <div class={
                checkHasProperties(props, [style.container, props.isFluid ? style.isFluid : ''])}>
            { props.children }
        </div>
    );
}

/* Divider */
interface DividerProps {
    label?: string;
}

const Divider : preact.FunctionalComponent<DividerProps> = ( props ) => {
    return (
        <div class={style.isDivider} data-content={props.label ? props.label : null} />
    );
}

/* Footer */
interface FooterProps {
    children?: any | any[];
}

const Footer : preact.FunctionComponent<FooterProps> = (props) => {
    return (
        <footer class={checkHasProperties(props, [style.footer, style.hasBackgroundWhite])}>
            <div class={style.content + ' ' + style.hasTextCentered}>
                {props.children}
            </div>
        </footer>
    )
}

export {
    Section,
    Container,
    Divider,
    Footer,
};

export {
    Level,
    LevelLeft,
    LevelRight,
    LevelItem
} from './level';