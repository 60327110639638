import { checkHasProperties, HasIsMarginless, HasIsMobile, HasTextAlign } from 'components/style';
import * as style from 'components/style.scss';
import { h, VNode } from 'preact';

interface LevelProps {
    children?: any | any[];
}

/* Level Item */
interface LevelItemProps extends LevelProps, HasTextAlign {
}

export const LevelItem : preact.FunctionalComponent<LevelItemProps> = ( props ) => {
    return (
        <div class={checkHasProperties(props, style.levelItem)}>
            { props.children }
        </div>
    )
}

/* Level Main/Left/Right */


export const LevelLeft : preact.FunctionalComponent<LevelProps> = ( props ) => {
    return (
        <div class={`
                ${style.levelLeft}`}>
            { props.children }
        </div>
    )
}

export const LevelRight : preact.FunctionalComponent<LevelProps> = ( props ) => {
    return (
        <div class={`
                ${style.levelRight}`}>
            { props.children }
        </div>
    )
}

interface LevelMainProps extends HasIsMobile, HasIsMarginless {
    children?: VNode<(typeof LevelItem | typeof LevelLeft  | typeof LevelRight)> | Array<VNode<(typeof LevelItem | typeof LevelLeft  | typeof LevelRight)>>;
}

export const Level : preact.FunctionalComponent<LevelMainProps> = ( props ) => {
    return (
        <nav class={checkHasProperties(props, [style.level])}>
            { props.children }
        </nav>
    )
}