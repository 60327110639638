import { h, VNode } from 'preact';

import {checkHasProperties, HasIsActive, HasTextAlign} from 'components/style';
import * as style from 'components/style.scss';

interface ModalProps {
    hasClose: boolean;
    children: any | any[];
}

export const Modal: preact.FunctionalComponent<ModalProps> = ( props ) => {
    return (
        <div class={style.modal}>
            <div class={style.modalBackground} />
            <div class={style.modalContent}>
                { props.children }
            </div>
            { props.hasClose ? <button class={style.modalClose + ' ' + style.isLarge} aria-label="close" /> : null }
        </div>
    );
}


/* Modal Card */
interface ModalCardHeadProps {
    hasClose?: boolean;
    onClose?: () => void;
    children?: any | any[];
}

export const ModalCardHead: preact.FunctionalComponent<ModalCardHeadProps> = ( props ) => {
    return (
        <header class={style.modalCardHead}>
            <p class={style.modalCardTitle}>{props.children}</p>
            { props.hasClose ? <button class={'delete'} aria-label="close" onClick={props.onClose} /> : null }
        </header>
    );
}

interface ModalCardBodyProps extends HasTextAlign {
    children: any | any[];
}

export const ModalCardBody: preact.FunctionalComponent<ModalCardBodyProps> = ( props ) => {
    return (
        <section class={checkHasProperties(props, style.modalCardBody)}>
            { props.children }
        </section>
    );
}

interface ModalCardFootProps {
    children: any | any[];
}

export const ModalCardFoot: preact.FunctionalComponent<ModalCardFootProps> = ( props ) => {
    return (
        <footer class={style.modalCardFoot}>
            { props.children }
        </footer>
    );
}

interface ModalCardProps extends HasIsActive {
    children: [VNode<typeof ModalCardHead> , VNode<typeof ModalCardBody>, VNode<typeof ModalCardFoot>] |
              [VNode<typeof ModalCardHead> , VNode<typeof ModalCardBody>];
}

export const ModalCard: preact.FunctionalComponent<ModalCardProps> = ( props ) => {
    return (
        <div class={checkHasProperties(props, style.modal)}>
            <div class={style.modalBackground} />§
            <div class={style.modalCard}>
                { props.children }
            </div>
        </div>
    );
}