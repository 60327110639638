import { h, VNode } from 'preact';

import { checkHasProperties,
    HasAddons,
    HasBackgroundColor,
    HasClass,
    HasColor,
    HasIsActive,
    HasIsLoading,
    HasIsOutlined,
    HasIsRounded,
    HasIsSpaced,
    HasIsStatic,
    HasSize,
    HasText,
    HasTextColor,
} from 'components/style';
import * as style from 'components/style.scss';

/* Notification */
interface NotificationProps extends HasColor {
    children?: any | any[];
}

export const Notification : preact.FunctionalComponent<NotificationProps> = ( props ) => {
    return (
        <div class={checkHasProperties(props, style.notification)}>
            { props.children }
        </div>
    );
}

/* Button(s) */
interface ButtonProps extends HasColor, HasIsRounded, HasIsLoading, HasIsStatic, HasSize, HasIsOutlined {
    label?: string;
    children?: any | any[];
    disabled?: boolean;
    onClick?: () => void;
    onMouseDown?: () => void;
    type?: string;
}

export const Button : preact.FunctionalComponent<ButtonProps> = (props) => {
    return (
        <button class={checkHasProperties(props, style.button)}
                onClick={(e) => (props.onClick ? props.onClick() : null)}
                onMouseDown={(e) => (props.onMouseDown ? props.onMouseDown() : null)}
            disabled={props.disabled ? true : false } type={props.type ? props.type : 'button'}>{props.label ? props.label : props.children}</button>
    );
}

interface ButtonsProps extends HasAddons {
    children: VNode<typeof Button> | Array<VNode<typeof Button>>;
}

export const Buttons: preact.FunctionalComponent<ButtonsProps> = ( props ) => {
    return (
        <div class={checkHasProperties(props, style.buttons)}>
            { props.children }
        </div>
    );
}

/* Tag(s) */
interface TagProps extends HasColor, HasIsRounded, HasSize {
    label: string;
}

export const Tag: preact.FunctionalComponent<TagProps> = ( props ) => {
    return (
        <span class={checkHasProperties(props, style.tag)}>
            {props.label}
        </span>
    )
}

interface TagsProps extends HasAddons {
    children?: VNode<typeof Tag> | Array<VNode<typeof Tag>>;
}

export const Tags: preact.FunctionalComponent<TagsProps> = ( props ) => {
    return (
        <div class={checkHasProperties(props, style.tags)}>
            { props.children }
        </div>
    )
}

/* (Sub)title */

interface SubtitleProps extends HasSize, HasClass, HasTextColor {
    title?: string;
    children?: any | any[];
}

interface TitleProps extends SubtitleProps, HasIsSpaced, HasTextColor {}

export const Title: preact.FunctionalComponent<TitleProps> = ( props ) => {
    return (
        <p class={checkHasProperties(props, style.title)}>
            {props.title ? props.title : props.children}
        </p>
    );
}

export const Subtitle: preact.FunctionalComponent<SubtitleProps> = ( props ) => {
    return (
        <p class={checkHasProperties(props, style.subtitle)}>
            {props.title ? props.title : props.children}
        </p>
    );
}

/* Paragraph */

interface ParagraphProps extends HasText {
    children: any | any[];
}

export const Paragraph: preact.FunctionalComponent<ParagraphProps> = ( props ) => {
    return (
        <p class={checkHasProperties(props, [])}>
            { props.children }
        </p>
    );
}

/* Box */
interface BoxProps extends HasBackgroundColor {
    children: any | any[];
}

export const Box: preact.FunctionalComponent<BoxProps> = ( props ) => {
    return (
        <div class={checkHasProperties(props, style.box)}>
            { props.children }
        </div>
    );
}

interface LoaderProps extends HasIsActive {
    isActive: boolean;
}

/* Loader */
export const Loader: preact.FunctionalComponent<LoaderProps> = (props) => {
    return (
        <div class={checkHasProperties(props, [style.loaderWrapper])}>
            <div class={checkHasProperties({}, [style.loader, style.isLoading])} />
        </div>
    )
}

export { default as Switch } from './switch';

export { Icon as Icon, Icons as Icons } from './icon';