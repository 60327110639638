import { h } from 'preact';

import { Column, Columns } from 'components/columns';
import { Box, Button, Icon, Icons, Loader } from 'components/elements';
import { Container } from 'components/layout';
import {ModalCard, ModalCardBody, ModalCardFoot, ModalCardHead} from 'components/modal';
import { Color } from 'components/style';
import * as style from 'components/style.scss';
import { useState } from 'preact/hooks';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm, reset } from 'redux-form';
import { authDoChangePassword, authDoResetPassword, authLogin, authLogout, authResetPasswordCode } from 'utils/redux/auth';

interface Props {
    email: string;
    password: string;
    setLoggedIn: (user:any) => void;
    resetForm: () => void;
    errorMsg?: string;
    loggingIn?: boolean;
    changePassword?: boolean;
    login: (email:string, password:string) => void;
    doChangePassword: (password:string) => void;
    refreshing?: boolean;
    handleSubmit: (values:any) => void;
    resetPasswordCode: (email:string) => void;
    passwordCodeSent: boolean;
    passwordCodeSending: boolean;
    doResetPassword: (email:string, code:string, password:string) => void;
    resettingPasswordDone: boolean;
    resettingPassword:boolean;
    logout: () => void;
}

const renderEmail = (field: any) => {
    return (
        <div class={style.field}>
            <label for="" class={style.label}>Email</label>
            <div class={style.control + ' ' + style.hasIconsLeft}>
                <input type="email" placeholder="e.g. alice@bob.com" class={style.input} required={false} {...field.input} />
                <Icon icon={Icons.envelope} iconSize={'lg'} isLeft={true} />
            </div>
            {field.meta.error && field.meta.touched && <p class={style.help + ' ' + style.isDanger}>{field.meta.error}</p>}
        </div>
    );
}

const renderResetCode = (field: any) => {
    return (
        <div class={style.field}>
            <label for="" class={style.label}>Återställningskod</label>
            <div class={style.control + ' ' + style.hasIconsLeft}>
                <input type="text" placeholder="000000" class={style.input} required={false} {...field.input} />
                <Icon icon={Icons.shieldAlt} iconSize={'lg'} isLeft={true} />
            </div>
        </div>
    );
}

const renderPassword = (field: any) => {
    return (
        <div class={style.field}>
            <label for="" class={style.label}>Lösenord</label>
            <div class={style.control + ' ' + style.hasIconsLeft}>
                <input type="password" placeholder="*******" class={style.input} required={false} {...field.input} />
                <Icon icon={Icons.lock} iconSize={'lg'} isLeft={true} />
            </div>
            { field.resetPassword ? <p class={style.help + ' ' + style.isLink} style={{cursor: 'pointer'}} onClick={field.resetPassword}>Glömt lösenordet?</p> : null }
        </div>
    )
}


const minLength8 = (value:string) => (!value || value.length < 8 ? 'Minsta tillåtna längd är 8 tecken' : undefined)
const validateEmail = (value:string) =>
  !value || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Ej en giltig email adress' : undefined
const notEmpty = (value:string) => (!value || value.length === 0 ? 'Fältet kan ej vara tomt' : undefined)
const passwordMatch = (passwordField:string) =>  (value:string, allValues:any) => 
  (value !== allValues[passwordField] ? 'Lösenorden matchar inte!' : undefined)

const passwordMatchPassword = passwordMatch('password');
const passwordMatchNewPassword = passwordMatch('newPassword');

const renderChangePassword = (field: any) => {
    return (
        <div class={style.field}>
            <label class={style.label}>{field.label}</label>
            <div class={style.control + ' ' + style.hasIconsLeft}>
                <input type="password" placeholder="*******" class={style.input} required={false} {...field.input} />
                <Icon icon={Icons.lock} iconSize={'lg'} isLeft={true} />
            </div>
            {field.meta.error && field.meta.touched && <p class={style.help + ' ' + style.isDanger}>{field.meta.error}</p>}
        </div>
    )
}

let Login: preact.FunctionalComponent<Props> = (props) => {
    const [showResetPasswordModal, setResetPasswordModal] = useState(false);

    if (props.resettingPasswordDone && showResetPasswordModal) {
        setResetPasswordModal(false);
    }
    return (
        <section class="hero is-light is-fullheight">
            <Loader isActive={props.refreshing === false ? false : true} />
            { props.refreshing === false ?
            <div class="hero-body">
                <Container>
                    <Columns isCentered={true}>
                        <Column class={[style.is5Tablet, style.is4Desktop, style.is12Mobile]}>
                            <Box>
                                {props.changePassword ?
                                    !showResetPasswordModal ?
                                        // @ts-ignore
                                        <form onSubmit={props.handleSubmit((values:any) => {
                                            props.doChangePassword(values.password);
                                        })}>
                                            <Field component={renderChangePassword} label='Var vänlig ange ett nytt lösenord' name="password" validate={[minLength8]}/>
                                            <Field component={renderChangePassword} label='Bekräfta lösenord' name="passwordRepeat" validate={[passwordMatchPassword]}/>
                                            { props.errorMsg ?
                                                <p class={style.hasTextDanger}>{props.errorMsg}</p>
                                                : null}
                                            <div class={[style.field, style.isGrouped, style.isGroupedRight].join(' ')}>
                                                <Button type='submit' color={Color.success} isLoading={props.loggingIn}>Byt lösenord</Button>
                                            </div>
                                        </form>
                                        : null
                                :
                                    !showResetPasswordModal ?
                                        <form onSubmit={async (e) => {
                                            e.preventDefault();
                                            props.login(props.email, props.password);
                                        }}>
                                            <Field component={renderEmail} name="email" />
                                            <Field component={renderPassword} name="password" resetPassword={() => {setResetPasswordModal(true);props.logout();}}/>
                                            { props.errorMsg ?
                                                <p class={style.hasTextDanger}>{props.errorMsg}</p>
                                                : null}
                                            { props.resettingPasswordDone ?
                                                <p class={style.hasTextSuccess}>Ditt lösenord har återställts, logga in med de nya uppgifterna.</p>
                                                : null}
                                            <div class={[style.field, style.isGrouped, style.isGroupedRight].join(' ')}>
                                                <Button type='submit' color={Color.success} isLoading={props.loggingIn}>Logga in</Button>
                                            </div>
                                        </form>
                                        : null
                                }
                            </Box>
                        </Column>
                    </Columns>
                </Container>
            </div>
            : null}
            { showResetPasswordModal ?
                // @ts-ignore
                <form  onSubmit={
                    props.handleSubmit((values:any) => {
                        console.log(values);
                        props.doResetPassword(values.email, values.code, values.newPassword);
                    })} >
                    <ModalCard isActive={true}>
                        <ModalCardHead hasClose={true} onClose={() => {setResetPasswordModal(false);}}>
                            Återställ lösenord.
                        </ModalCardHead>
                            <ModalCardBody>
                            <div class={style.content}>
                                <ol type="1">
                                    <li>Fyll i din Email address nedan.</li>
                                    <li>Tryck på knappen "Skicka återställningskod."</li>
                                    <li>Kontrollera din Email och skriv in återställningskoden nedan.</li>
                                    <li>Fyll i ditt nya lösenord och tryck på knappen Återställ.</li>
                                </ol>
                                { props.errorMsg ?
                                                <p class={style.hasTextDanger}>{props.errorMsg}</p>
                                                : null}
                                { props.passwordCodeSent ?
                                    <p class={style.hasTextSuccess}>Återställningskoden har skickats.</p>
                                    : null}
                                <Field component={renderEmail} name="email" validate={[validateEmail]} />
                                <Field component={renderResetCode} name="code" validate={[notEmpty]}/>
                                <Field component={renderChangePassword} label='Nytt lösenord' name="newPassword" validate={[minLength8]}/>
                                <Field component={renderChangePassword} label='Bekräfta lösenord' name="newPasswordRepeat" validate={[passwordMatchNewPassword]}/>
                            </div>
                            </ModalCardBody>
                            <ModalCardFoot>
                                <Button color={Color.info} 
                                    isLoading={props.passwordCodeSending}
                                    onClick={() => {
                                        if (validateEmail(props.email) === undefined) {
                                            // Email is ok
                                            props.resetPasswordCode(props.email);
                                        }
                                    }}>Skicka återställningskod</Button>
                                <Button type='submit' color={Color.success} isLoading={props.resettingPassword}>Återställ</Button>
                            </ModalCardFoot>
                    </ModalCard>
                </form>
                : null
            }
        </section>
    );
}

// @ts-ignore
Login = reduxForm({ form: 'login'})(Login);
const formSelector = formValueSelector('login');

Login = connect((state: any) => {
    return {
        email: formSelector(state, 'email'),
        password: formSelector(state, 'password'),
        errorMsg: state.auth.error,
        loggingIn: state.auth.loggingIn,
        changePassword: state.auth.changePassword,
        refreshing: state.auth.refreshing,
        passwordCodeSent: state.auth.passwordCodeSent,
        passwordCodeSending: state.auth.passwordCodeSending,
        resettingPassword: state.auth.resettingPassword,
        resettingPasswordDone: state.auth.resettingPasswordDone,
    };
}, (dispatch) => {
    return {
        login: (email: string, password: string) => dispatch(authLogin(email, password)),
        logout: () => dispatch((authLogout())),
        resetForm: () => (dispatch(reset('login'))),
        doChangePassword: (password: string) => dispatch(authDoChangePassword(password)),
        resetPasswordCode: (email: string) => dispatch(authResetPasswordCode(email)),
        doResetPassword: (email:string, code:string, password:string) => dispatch(authDoResetPassword(email,code,password)),
    };
// @ts-ignore
})(Login);

export default Login;