import * as style from './style.scss';

export interface HasColor {
    color?: string;
    isLight?: boolean;
}

export interface HasTextColor {
    textColor?: string;
}

export interface HasIsRounded {
    isRounded?: boolean;
}

export interface HasIsLoading {
    isLoading?: boolean;
}

export interface HasIsStatic {
    isStatic?: boolean;
}

export interface HasTextCentered {
    hasTextCentered?: boolean;
}

export interface HasClass {
    class?: string | string[];
}

export interface HasIsMobile {
    isMobile?: boolean;
}

export interface HasIsActive {
    isActive?: boolean;
}

export interface HasSize {
    size?: string;
}

export interface HasAddons {
    hasAddons?: boolean;
}

export interface HasIsSpaced {
    isSpaced?: boolean;
}

export interface HasTextAlign {
    textAlign?: string;
}

export interface HasOffset {
    offset?: string;
}

export interface HasIsCentered {
    isCentered?: boolean;
}

export interface HasIsLeft {
    isLeft?: boolean;
}

export interface HasText extends HasTextCentered, HasSize, HasTextColor {
    hasTextJustified?: boolean;
    hasTextRight?: boolean;
    hasTextLeft?: boolean;
    isCapitalized?: boolean;
    isLowercase?: boolean;
    isUppercase?: boolean;
    isItalic?: boolean;
    heading?: boolean;
}

export interface HasIsOutlined {
    isOutlined?: boolean;
}

export interface HasBackgroundColor {
    backgroundColor?: string;
}

export interface HasIsMarginless {
    isMarginless?: boolean;
}

const CHECK_STYLESHEET = 'CHECK_STYLESHEET';

const hasTable: {[key:string]: string | null} = {
    color: null,
    textColor: null,
    backgroundColor: null,
    isLight: CHECK_STYLESHEET,
    size: null,
    offset: null,
    isRounded: CHECK_STYLESHEET,
    isLoading: CHECK_STYLESHEET,
    isStatic: CHECK_STYLESHEET,
    hasTextCentered: CHECK_STYLESHEET,
    isMobile: CHECK_STYLESHEET,
    isActive: CHECK_STYLESHEET,
    hasAddons: CHECK_STYLESHEET,
    isSpaced: CHECK_STYLESHEET,
    hasTextJustified: CHECK_STYLESHEET,
    hasTextRight: CHECK_STYLESHEET,
    hasTextLeft: CHECK_STYLESHEET,
    isCapitalized: CHECK_STYLESHEET,
    isLowercase: CHECK_STYLESHEET,
    isUppercase: CHECK_STYLESHEET,
    isItalic: CHECK_STYLESHEET,
    heading: CHECK_STYLESHEET,
    isOutlined: CHECK_STYLESHEET,
    textAlign: null,
    isMarginless: CHECK_STYLESHEET,
    isCentered: CHECK_STYLESHEET,
    isLeft: CHECK_STYLESHEET,
};

export const checkHasProperties = ( props: any, componentClasses: string | string[]) => {
    let classes: string = '';
    const cstyle = style as {[key:string]: string};
    const cprops = props as {[key:string]: string};

    for (const key in hasTable) {
        if (key in cprops) {
            if (hasTable[key] === null) {
                classes += ' ' + cprops[key];
            } else if (cprops[key] && hasTable[key] === CHECK_STYLESHEET) {
                classes += ' '  + cstyle[key];
            }
        }
    }

    if ('class' in props) {
        classes += ' ' +
            (Array.isArray(props.class) ? props.class.join(' ') : props.class);
    } 

    return  classes + ' ' +
            (Array.isArray(componentClasses) ? componentClasses.join(' ') : componentClasses);
}

export const Color: { [key: string]: string; } = {
    primary: style.isPrimary,
    link: style.isLink,
    info: style.isInfo,
    success: style.isSuccess,
    warning: style.isWarning,
    danger: style.isDanger,
    white: style.isWhite,
    dark: style.isDark,
    black: style.isBlack,
    text: style.isText,
    light: style.isLight,
}

export const BackgroundColor: { [key: string]: string; } = {
    primary: style.hasBackgroundPrimary,
    link: style.hasBackgroundLink,
    info: style.hasBackgroundInfo,
    success: style.hasBackgroundSuccess,
    warning: style.hasBackgroundWarning,
    danger: style.hasBackgroundDanger,
    white: style.hasBackgroundWhite,
    dark: style.hasBackgroundDark,
    black: style.hasBackgroundBlack,
    light: style.hasBackgroundLight,
    grey: style.hasBackgroundGrey,
}

export const TextColor: { [key: string]: string; } = {
    primary: style.hasTextPrimary,
    link: style.hasTextLink,
    info: style.hasTextInfo,
    success: style.hasTextSuccess,
    warning: style.hasTextWarning,
    danger: style.hasTextDanger,
    white: style.hasTextWhite,
    dark: style.hasTextDark,
    black: style.hasTextBlack,
}

export const Size: { [key: string]: string; } = {
    small: style.isSmall,
    normal: style.isNormal,
    medium: style.isMedium,
    large: style.isLarge,
}

export const SizeNr: { [key: string]: string; } = {
    is1: style.is1,
    is2: style.is2,
    is3: style.is3,
    is4: style.is4,
    is5: style.is5,
    is6: style.is6
}

export const ColumnSize: {[key: string]: string;} = {
    isThreeQuarters: style.isThreeQuarters,
    isTwoThirds: style.isTwoThirds,
    isHalf: style.isHalf,
    isOneThird: style.isOneThird,
    isOneQuarter: style.isOneQuarter,
    isFull: style.isFull
}

export const ColumnOffset = {
    is1: style.isOffset1,
    is2: style.isOffset2,
    is3: style.isOffset3,
    is4: style.isOffset4,
    is5: style.isOffset5,
    is6: style.isOffset6,
    is7: style.isOffset7,
    is8: style.isOffset8,
    is9: style.isOffset9,
    is10: style.isOffset10,
    is11: style.isOffset11,
}

export const TextSize: {[key: string]: string;} = {
    is1: style.isSize1,
    is2: style.isSize2,
    is3: style.isSize3,
    is4: style.isSize4,
    is5: style.isSize5,
    is6: style.isSize6
}

export const TextAlign = {
    left: style.hasTextLeft,
    center: style.hasTextCentered,
    right: style.hasTextRight,
}