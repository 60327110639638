import { h } from 'preact';
import { useState } from 'preact/hooks';

import { Column, Columns } from 'components/columns';
import {
    Box,
    Button,
    Buttons,
    Icon,
    Icons,
    Loader,
    Notification,
    Paragraph,
    Subtitle,
    Switch,
    Tag,
    Tags,
    Title
} from 'components/elements';
import {
    Container,
    Divider,
    Level,
    LevelItem,
    LevelLeft,
    LevelRight,
    Section
 } from 'components/layout';
import {ModalCard, ModalCardBody, ModalCardFoot, ModalCardHead} from 'components/modal';
import { BackgroundColor, Color, ColumnOffset, ColumnSize, Size, SizeNr, TextAlign, TextColor, TextSize } from 'components/style';
import * as style from 'components/style.scss';
import { Weekday, WeekdayTag } from './data';
import ScheduleEntry from './ScheduleEntry';
import ScheduleEntryForm from './ScheduleEntryForm';
import * as cstyles from './style.scss';

import { connect } from 'react-redux';
import { outletActivate, outletScheduleAdd, outletScheduleDelete, outletScheduleSetEnabled, outletScheduleUpdate } from 'utils/redux/outlets';

interface Props {
    on: boolean;
    isFetching: boolean;
    data?: any;
    scheduleAdd: (data:any) => void;
    scheduleUpdate: (schedule:any, data:any) => void;
    scheduleDelete: (id:string) => void;
    outletActivate: (id:string) => void;
    scheduleSetEnabled: (schedule:any, enabled: boolean) => void;
}

const formatScheduleData = (values:any) => {
    const days:{ [key: string]: boolean; } = {};
    WeekdayTag.map((e) => {
        if (values.days.includes(e.day)) {
            days[e.day] = true;
        } else {
            days[e.day] = false;
        }
    })
    return {
        hour: values.time ? values.time.hour : 12,
        minute: values.time ? values.time.minute : 0,
        active: true,
        repeat: values.repeat,
        ...days,
    } 
}

let BlockHeater : preact.FunctionalComponent<Props> = ( props ) => {
    const [showAddModal, setShowAddModal] = useState(false);
    let schedule = [];
    let on = false;
    let name = '';
    let activate = false;
    let id:string;
    let activations:number= 0;

    if (props.data) {
        on = props.data.active;
        activations = props.data.activations ? props.data.activations : 0;
        activate = props.data.activate;
        id = props.data.id;
        if (props.data.schedule.items) {
            const items = props.data.schedule.items.filter((e:any) => (!e._deleted));
            let repeat = items.filter((e:any) => (e.repeat));
            let nonRepeat = items.filter((e:any) => (!e.repeat));
            const sort = (a:any, b:any) => {
                const aMinutes = a.hour*60 + a.minute;
                const bMinutes = b.hour*60 + b.minute;

                if (aMinutes < bMinutes) {
                    return -1;
                }  if (aMinutes === bMinutes) {
                    return a.id.localeCompare(b.id);
                } 
                    return 1;
                
            }
            repeat = repeat.sort(sort);
            nonRepeat = nonRepeat.sort(sort);
            schedule = nonRepeat.concat(repeat);
        }
        name = props.data.name;
    }

    const submit = (values:any) => {
        setShowAddModal(false);
        props.scheduleAdd({...formatScheduleData(values), scheduleEntryOutletId: props.data.id});
    }
    if (props.data === null && !props.isFetching) {
        return (
            <Section>
                <Container class={style.mBSm + ' ' + style.hasTextCentered}>
                    <Title title='Motorvärmare' size={SizeNr.is3}/>
                </Container>
                <Container textAlign={TextAlign.center} class={style.mTLg}>
                    <Title title='Du har ingen motorvärmare kopplad till din användare.' size={SizeNr.is5}/>
                </Container>
            </Section>
        )
    }
      
    return (
        <Section>
            <Loader isActive={props.isFetching} />
            <Container class={style.mBSm + ' ' + style.hasTextCentered}>
                <Title title='Motorvärmare' size={SizeNr.is3}/>
            </Container>
            <Container>
                <Columns>
                    <Column hasTextCentered={true} size={ColumnSize.isOneThird}>
                        <Paragraph heading={true}>Plats</Paragraph>
                        <Title title={name} size={SizeNr.is3}/>
                    </Column>
                    <Column>
                        <Columns isMobile={true}>
                            <Column  hasTextCentered={true}>
                                <Paragraph heading={true}>Status</Paragraph>
                                <Title title={on ? 'På' : 'Av'} textColor={on ? TextColor.success : TextColor.black}  size={SizeNr.is3}/>
                            </Column>
                            <Column hasTextCentered={true}>
                                <Paragraph heading={true}>Direktstarta (120min)</Paragraph>
                                <Button label='Starta' color={Color.success} disabled={on || activations === 5} isLoading={activate} onClick={() => (props.outletActivate(id))}/>
                            </Column>
                        </Columns>
                    </Column>
                </Columns>
            </Container>
            <Container textAlign={TextAlign.center}>
                <Divider />
                <Columns isMobile={false}>
                    <Column hasTextCentered={true} size={ColumnSize.isOneThird}>
                        <Paragraph heading={true}>Starter idag (Max 5 st)</Paragraph>
                        <Title title={activations.toString()} textColor={ activations===5 ? TextColor.danger : undefined} size={SizeNr.is3}/>
                    </Column>
                    <Column >
                        <Title title='Schema' size={SizeNr.is4}/>
                        <Subtitle title='Avresetid' size={SizeNr.is5}/>
                    </Column>
                    <Column id={cstyles.addColumn}>
                        <Button color={Color.success} isOutlined={true} onClick={ () => { setShowAddModal(true);}}>
                            <Icon icon={Icons.plus} size={Size.large}/><span>Lägg till</span>
                        </Button>
                    </Column>
                </Columns>

                { schedule.map((e:any) => (
                    <ScheduleEntry data={e}
                                   key={e}
                                   scheduleDelete={() => (props.scheduleDelete(e))}
                                   scheduleSetEnabled={(enabled:boolean) => (props.scheduleSetEnabled(e, enabled))}
                                   scheduleUpdate={(data:any) => {
                                       props.scheduleUpdate(e, formatScheduleData(data));
                                   } } />
                ))}

            </Container>

            { /* Add Modal */}
            { showAddModal ?
                <ModalCard isActive={true}>
                    <ModalCardHead hasClose={false}>
                        Lägg till avresetid
                    </ModalCardHead>
                    <ModalCardBody>
                        { 
                        // @ts-ignore
                        <ScheduleEntryForm onSubmit={submit} onCancel={() => {setShowAddModal(false);}} />
                        }
                    </ModalCardBody>
                </ModalCard>
                : null
            }
        </Section>
    );
}

BlockHeater = connect((state:any) => {
        let outlet = null;
        const outlets = state.outlets.outlets;
        if (outlets && outlets.length !== 0) {
            outlet = outlets[0];
            if (state.blockheat.outlet) {
                outlet = outlets.find((e:any) => (e.id === state.blockheat.outlet));
                if (!outlet) {
                    outlet = null;
                }
            }
        }
        return {
            isFetching: state.outlets.isFetching || !state.outlets.updated,
            data: outlet,
        };
    },
    (dispatch:any) => {
        return {
            scheduleAdd: (data:any) => (dispatch(outletScheduleAdd(data))),
            scheduleUpdate: (schedule:any, data:any) => (dispatch(outletScheduleUpdate(schedule, data))),
            scheduleDelete: (id:string) => (dispatch(outletScheduleDelete(id))),
            scheduleSetEnabled: (schedule:any, enabled: boolean) => (dispatch(outletScheduleSetEnabled(schedule, enabled))),
            outletActivate: (id:string) => (dispatch(outletActivate(id))),
        }
    }
// @ts-ignore
)(BlockHeater);

export default BlockHeater;