import { h, VNode } from 'preact';

import * as style from 'components/style.scss';

interface Props {
    label?: string;
    link?: string;
    img?: string;
    width?: number;
    height?: number;
    onClick?: () => void;
    children?: VNode<any>;
}

interface ImageProps {
    src?: string;
    width?: number;
    height?: number;
}

const NavbarItem: preact.FunctionalComponent<Props> = props => {
    let display : any;
    if (props.label) {
        display = (
            <a class={style.navbarItem} href={props.link} onClick={props.onClick}>
                { props.label }
            </a>
        );
    }
    else if (props.img) {
        const displayProps: ImageProps = {
            src: props.img,
            width: props.width,
            height: props.height
        };

        display = (<a class={style.navbarItem} href={props.link} onClick={props.onClick}><img {...displayProps} /></a>);
    }
    else {
        display = (<div class={style.navbarItem} onClick={props.onClick}> {props.children}</div>);
    }

    return display;
}

export default NavbarItem;