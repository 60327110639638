// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:13c93b66-a56b-4e15-b3f9-44d898d4def3",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_GGcygbcod",
    "aws_user_pools_web_client_id": "64ceg0438bmgpue082tj6d5ie8",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://f5aqhkh6hvczphujswsdljrora.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "blockheater-20200406124257-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://d1cptqoy1fobca.cloudfront.net"
};


export default awsmobile;
