import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { persistStore } from 'redux-persist';
import { authReducer } from './auth';
import { blockheatReducer } from './blockheat';
import { outletsReducer } from './outlets';

const asyncDispatchMiddleware = (store:any) => (next:any) => (action:any) => {
	let syncActivityFinished = false;
	let actionQueue:  any[] = [];

	function flushQueue() {
		actionQueue.forEach(a => store.dispatch(a)); // flush queue
		actionQueue = [];
	}

	function asyncDispatch(asyncAction:any) {
		actionQueue = actionQueue.concat([asyncAction]);

		if (syncActivityFinished) {
			flushQueue();
		}
	}

	const actionWithAsyncDispatch =
		Object.assign({}, action, { asyncDispatch });

	const res = next(actionWithAsyncDispatch);

	syncActivityFinished = true;
	flushQueue();

	return res;
};

const appReducer = combineReducers({
	auth: authReducer,
	form: formReducer,
	outlets: outletsReducer,
	blockheat: blockheatReducer,
});

const ACTION_RESET_APP = 'ACTION_RESET_APP';
const resetApp = () => ({type:ACTION_RESET_APP});

const rootReducer = (state:any, action:any) => {
	if (action.type === ACTION_RESET_APP) {
	  state = undefined;
	}
  
	return appReducer(state, action);
  }

const reduxStore = createStore(rootReducer, compose(applyMiddleware(asyncDispatchMiddleware))); // ,
	// @ts-ignore
	// typeof devToolsExtension === 'function' ? devToolsExtension() : undefined));
const persistor = persistStore(reduxStore);


export { reduxStore as store, persistor, resetApp };