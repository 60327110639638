import { outletFetch } from './outlets';
const BLOCKHEAT_SET_OUTLET = 'BLOCKHEAT_SET_OUTLET';

const defaultState = { outlet: null };

const blockheatSetOutlet = (id:any) => {
    return {
        type: BLOCKHEAT_SET_OUTLET,
        id
    };
}

const blockheatReducer = (state = defaultState, action: any) => {
    switch (action.type) {
    case BLOCKHEAT_SET_OUTLET:
        action.asyncDispatch(outletFetch(action.id));
        return Object.assign({}, state, {outlet: action.id});
    }

    return state;
}

export {
    blockheatReducer,
    blockheatSetOutlet
};