import * as style from 'components/style.scss';
import { cloneElement, h, VNode } from 'preact';
import { useCallback, useState } from "preact/hooks";
import NavbarBrand from './NavbarBrand';
import NavbarMenu from './NavbarMenu';

interface Props {
    children: [VNode<typeof NavbarBrand>, VNode<typeof NavbarMenu>];
}

const Navbar : preact.FunctionComponent<Props> = props => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const setActive = useCallback(() => {
        setIsActive(!isActive);
      }, [isActive]);
    return (
        <nav class={style.navbar} role='navigation' aria-label='main navigation'>
            {cloneElement(props.children[0], {isActive, setActive})}
            {cloneElement(props.children[1], {isActive})}
        </nav>
    );
}

export default Navbar;