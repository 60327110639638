import { h, VNode } from 'preact';

import * as style from 'components/style.scss';
import NavbarItem from './NavbarItem';

interface Props {
    label: string;
    isActive?: boolean;
    isHoverable?: boolean;
    children?: VNode<typeof NavbarItem> | Array<VNode<typeof NavbarItem>>;
}

const NavbarDropdown : preact.FunctionalComponent<Props> = ( props ) => {
    return (
        <div class={[style.navbarItem, style.hasDropdown].concat(
                props.isHoverable ? [ style.isHoverable] : []
            ).join(' ')}>

            <a class={style.navbarLink}>
                { props.label }
            </a>

            <div class={style.navbarDropdown}>
                { props.children }
            </div>
        </div>
    );
}

export default NavbarDropdown;