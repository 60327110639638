import { h, VNode } from 'preact';
import NavbarItem from './NavbarItem';

import { checkHasProperties, HasIsActive } from 'components/style';
import * as style from 'components/style.scss';

interface Props extends HasIsActive {
    burger?: boolean;
    setActive?: any;
    children : Array<VNode<typeof NavbarItem>> | VNode<typeof NavbarItem>;
}

const NavbarBrand : preact.FunctionalComponent<Props> = (props) => {
    return (
        <div class={style.navbarBrand}>
        {
            props.children
        }
        {
            props.burger ? 
            (
                <a role="button" class={checkHasProperties(props, style.navbarBurger)} aria-label="menu" aria-expanded="false" onClick={props.setActive}>
                    <span aria-hidden="true"/>
                    <span aria-hidden="true"/>
                    <span aria-hidden="true"/>
                </a>
            )
            :
            null
        }
        </div>
    )
}

export default NavbarBrand;