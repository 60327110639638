import * as style from 'components/style.scss';
import { h, VNode } from 'preact';
import NavbarItem from './NavbarItem';

interface Props
{
    children: VNode<typeof NavbarItem> | Array<VNode<typeof NavbarItem>>;
}

const NavbarStart : preact.FunctionalComponent<Props> = (props) => {
    return (
        <div class={style.navbarStart}>
            { props.children }
        </div>
    );
}

const NavbarEnd : preact.FunctionalComponent<Props> = (props) => {
    return (
        <div class={style.navbarEnd}>
            { props.children }
        </div>
    );
}

export {
    NavbarStart,
    NavbarEnd
};