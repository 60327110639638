// tslint:disable
// this is an auto generated file. This will be overwritten

export const createOutlet = /* GraphQL */ `
  mutation CreateOutlet(
    $input: CreateOutletInput!
    $condition: ModelOutletConditionInput
  ) {
    createOutlet(input: $input, condition: $condition) {
      id
      owner
      name
      description
      active
      activated
      activations
      activate
      schedule {
        items {
          id
          hour
          minute
          active
          repeat
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        nextToken
      }
      site
      section
      sensor
    }
  }
`;
export const updateOutlet = /* GraphQL */ `
  mutation UpdateOutlet(
    $input: UpdateOutletInput!
    $condition: ModelOutletConditionInput
  ) {
    updateOutlet(input: $input, condition: $condition) {
      id
      owner
      name
      description
      active
      activated
      activations
      activate
      schedule {
        items {
          id
          hour
          minute
          active
          repeat
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        nextToken
      }
      site
      section
      sensor
    }
  }
`;
export const deleteOutlet = /* GraphQL */ `
  mutation DeleteOutlet(
    $input: DeleteOutletInput!
    $condition: ModelOutletConditionInput
  ) {
    deleteOutlet(input: $input, condition: $condition) {
      id
      owner
      name
      description
      active
      activated
      activations
      activate
      schedule {
        items {
          id
          hour
          minute
          active
          repeat
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        nextToken
      }
      site
      section
      sensor
    }
  }
`;
export const createScheduleEntry = /* GraphQL */ `
  mutation CreateScheduleEntry(
    $input: CreateScheduleEntryInput!
    $condition: ModelScheduleEntryConditionInput
  ) {
    createScheduleEntry(input: $input, condition: $condition) {
      id
      hour
      minute
      active
      repeat
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      outlet {
        id
        owner
        name
        description
        active
        activated
        activations
        activate
        schedule {
          nextToken
        }
        site
        section
        sensor
      }
    }
  }
`;
export const updateScheduleEntry = /* GraphQL */ `
  mutation UpdateScheduleEntry(
    $input: UpdateScheduleEntryInput!
    $condition: ModelScheduleEntryConditionInput
  ) {
    updateScheduleEntry(input: $input, condition: $condition) {
      id
      hour
      minute
      active
      repeat
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      outlet {
        id
        owner
        name
        description
        active
        activated
        activations
        activate
        schedule {
          nextToken
        }
        site
        section
        sensor
      }
    }
  }
`;
export const deleteScheduleEntry = /* GraphQL */ `
  mutation DeleteScheduleEntry(
    $input: DeleteScheduleEntryInput!
    $condition: ModelScheduleEntryConditionInput
  ) {
    deleteScheduleEntry(input: $input, condition: $condition) {
      id
      hour
      minute
      active
      repeat
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      outlet {
        id
        owner
        name
        description
        active
        activated
        activations
        activate
        schedule {
          nextToken
        }
        site
        section
        sensor
      }
    }
  }
`;
