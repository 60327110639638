import { h } from 'preact';

import { checkHasProperties, Color, HasColor, HasIsRounded, HasTextColor} from 'components/style';
import * as style from 'components/style.scss';

interface Props extends HasColor, HasIsRounded, HasTextColor {
    id: number;
    label?: string;
    isRtl?: boolean;
    disabled?: boolean;
    isOutlined?: boolean;
    checked?: boolean;
    isThin?: boolean;
    onChange?: (checked: boolean)=>void;
}

const Switch : preact.FunctionalComponent<Props> = ( props ) => {
    return (
        <div class={style.field}>
            <input id={props.id.toString()} name={props.id.toString()} type='checkbox'
                class={checkHasProperties(props, `${style.switchSlider}
                    ${props.isRtl ? style.isRtl : ''}
                    ${props.isOutlined ? style.isOutlined : ''}
                    ${props.isThin ? style.isThin : ''}`)}
                checked={props.checked}
                disabled={props.disabled}
                onClick={(e:any) => (props.onChange ? props.onChange(e.target.checked) : null)}/>    
            <label for={props.id.toString()} class={(props.checked ? style.hasTextWeightBold : '') + ' ' + props.textColor} style={!props.checked ? 'text-decoration: line-through' : ''}>{props.label}</label>
        </div>
        
    );
}

export default Switch;