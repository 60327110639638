import {
    Box,
    Button,
    Buttons,
    Icon,
    Icons,
    Switch,
    Tag,
    Tags,
    Title } from 'components/elements';
import {
    Level,
    LevelItem,
    LevelLeft,
    LevelRight,
 } from 'components/layout';
import {ModalCard, ModalCardBody, ModalCardFoot, ModalCardHead} from 'components/modal';
import { h } from 'preact';
import { useState } from "preact/hooks";
import { ScheduleDataJSON, ScheduleJSON, Weekday, WeekdayTag } from './data';
import ScheduleEntryForm from './ScheduleEntryForm';

import { BackgroundColor, Color, Size, TextAlign, TextColor } from 'components/style';
import { checkPropTypes } from 'prop-types';



interface Props {
    data: any;
    scheduleDelete: () => void;
    scheduleUpdate: (data:any) => void;
    scheduleSetEnabled: (enabled: boolean) => void;
}

const formatTime = (hour:number, minute:number): string =>  {
    let str = '';
    if (hour < 10) {
        str += '0';
    }
    str += hour.toString();
    str += ':';
    if (minute < 10) {
        str += '0';
    }
    str += minute.toString();
    return str;
}

const formatData = (data:ScheduleDataJSON) => {
    const days: Weekday[] = [];
    WeekdayTag.forEach(element => {
        if (data[element.day]) {
            days.push(element.day);
        }
    });
    return { repeat: data.repeat, time: {hour: data.hour, minute: data.minute}, days};
}

const ScheduleEntry: preact.FunctionalComponent<Props> = ( props ) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    return (
        <Box backgroundColor={props.data.active ? BackgroundColor.info: BackgroundColor.grey}>
            <Level isMobile={true}>
                <LevelLeft>
                    <LevelItem>
                        <Title textColor={TextColor.white}>{formatTime(props.data.hour, props.data.minute)}</Title>
                    </LevelItem>
                    <LevelItem>
                        { props.data.repeat ?
                            <Icon icon={Icons.redo} size={Size.medium} textColor={TextColor.white} iconSize='lg'/>
                            : null }
                    </LevelItem>
                </LevelLeft>
                <LevelRight>
                    <LevelItem textAlign={TextAlign.right}>
                        <Switch textColor={TextColor.white} isRtl={true} label={'Aktiverad'} isRounded={true} checked={props.data.active} id={props.data.id} onChange={enabled => {props.scheduleSetEnabled(enabled);}} />
                    </LevelItem>
                </LevelRight>
            </Level>
            <Level isMobile={true} isMarginless={true}>
                <LevelLeft>
                    <LevelItem textAlign={TextAlign.right}>
                        <Tags hasAddons={true}>
                            {
                                WeekdayTag.map((e, i) => {
                                    return <Tag label={e.tag} color={ props.data[e.day] ? Color.success : Color.white } isRounded={true} key={i} />
                                })
                            }
                        </Tags>
                    </LevelItem>
                </LevelLeft>
                <LevelRight>
                    <LevelItem>
                        <Buttons>
                            <Button color={Color.white} isOutlined={true} size={Size.small} onClick={() => { setShowEditModal(true); }}>
                                <Icon icon={Icons.pen} size={Size.large}/>
                            </Button>
                            <Button color={Color.white} isOutlined={true} size={Size.small} onClick={() => { setShowDeleteModal(true);}}>
                                <Icon icon={Icons.trash} size={Size.large}/>
                            </Button>
                        </Buttons>
                    </LevelItem>
                </LevelRight>
            </Level>

            { /* Delete Modal */}
            <ModalCard isActive={showDeleteModal}>
                <ModalCardHead hasClose={false}>
                    <Icon icon={Icons.trash} size={Size.normal}/>
                </ModalCardHead>
                <ModalCardBody textAlign={TextAlign.center}>
                    <span>Är du säker på att du vill ta bort:</span>
                    <br />
                    {formatTime(props.data.hour, props.data.minute)}
                    { props.data.repeat ?
                        <Icon icon={Icons.redo} size={Size.medium} iconSize='sm'/>
                        : null }
                    <div style='display: flex; justify-content: center;'>
                        <Tags hasAddons={true}>
                            {
                                WeekdayTag.map((e, i) => {
                                    return <Tag label={e.tag} color={ props.data[e.day] ? Color.success : Color.light } isRounded={true} key={i} />
                                })
                            }
                        </Tags>
                    </div>
                </ModalCardBody>
                <ModalCardFoot>
                    <Button color={Color.danger} isOutlined={false} size={Size.normal} onClick={() => {setShowDeleteModal(false); props.scheduleDelete();}}>
                        <Icon icon={Icons.trash} size={Size.large}/><span>Ta bort</span>
                    </Button>
                    <Button isOutlined={false} size={Size.normal} onClick={() => (setShowDeleteModal(false))}>
                        Avbryt
                    </Button>
                </ModalCardFoot>
            </ModalCard>

            { /* Edit Modal */ }
            { showEditModal ?
                <ModalCard isActive={true}>
                    <ModalCardHead hasClose={false}>
                        Ändra
                    </ModalCardHead>
                    <ModalCardBody textAlign={TextAlign.left}>
                        {
                            // @ts-ignore
                            <ScheduleEntryForm
                                onSubmit={(data) => {setShowEditModal(false); props.scheduleUpdate(data)}}
                                onCancel={() => {setShowEditModal(false);}}
                                data={formatData(props.data)} />
                        }
                    </ModalCardBody>
                </ModalCard>
                : null
            }
        </Box>
    );
}

export default ScheduleEntry;