import { h } from "preact";
import { Route, Router, RouterOnChangeArgs } from "preact-router";

import { connect, Provider } from 'react-redux';
import { store } from 'utils/redux';

import { Footer } from 'components/layout';
import BlockHeater from "routes/blockheater/BlockHeater";
import Login from 'routes/login/Login';
import { authIsLoggedIn, authLogout } from 'utils/redux/auth';
import Header from "./header";

import { blockheatSetOutlet } from 'utils/redux/blockheat';

if ((module as any).hot) {
    // tslint:disable-next-line:no-var-requires
    require("preact/debug");
}

interface PrivateRouteProps {
    authed?: boolean;
    component: any;
}

// @ts-ignore
const HeaderConnected = connect( (state:any) => {
        return {
            outlets: state.outlets.outlets,
        }
    },
    (dispatch) => {
        return {
            logout: () => dispatch(authLogout()),
            setOutlet: (id:string) => (dispatch(blockheatSetOutlet(id)))
        }
// @ts-ignore
    })(Header);

let PrivateRoute: preact.FunctionalComponent<PrivateRouteProps> = ({ authed, component, ...props }) => {
    if (authed) {
        return (<div><HeaderConnected /><Route component={component} {...props} /></div>);
    }

    return (<Route component={Login} {...props} />)

}

PrivateRoute = connect(
    (state: { auth: any}) => {
        return { authed: authIsLoggedIn(state.auth) };
    }
    // @ts-ignore
)(PrivateRoute);

const App: preact.FunctionalComponent = () => {
    let currentUrl: string;
    const handleRoute = (e: RouterOnChangeArgs) => {
        currentUrl = e.url;
    };

    return (
        <div id="app">
            <Provider store={store}>
                { /* <PersistGate loading={null} persistor={persistor}> */}
                <Router onChange={handleRoute}>
                    <PrivateRoute path="/" component={BlockHeater} />
                </Router>
                {/*</PersistGate> */}
            </Provider>
            <Footer><a href="mailto:service@sensact.se"> &copy; SENSACT SYSTEMS AB</a></Footer>
        </div>
    );
};

export default App;
