import { authIsLoggedIn } from './auth';
import { blockheatSetOutlet } from './blockheat';
import { outletsFetch } from './outlets';
import { store } from './store';

// An array of actor functions taking a `state` object and `dispatch` function
type Actor = (state: any, dispatch: any) => any;
const actors: Actor[] = [
  /* Fetch outlets after login */
  (state, dispatch) => {
    if (!state.outlets.updated && authIsLoggedIn(state.auth)) {
      dispatch(outletsFetch());
    }
  },

  /* Set blockheat view outlet to first in array if not set */
  (state, dispatch) => {
    if (state.outlets.updated && state.outlets.outlets.length > 0 && !state.blockheat.outlet) {
      dispatch(blockheatSetOutlet(state.outlets.outlets[0].id));
    }
  }
];

let acting = false
store.subscribe(() => {
  // Ensure that any action dispatched by actors do not result in a new
  // actor run, allowing actors to dispatch with impunity.
  if (acting) {
    return;
  }
  acting = true
  actors.forEach((actor) => {
    actor(store.getState(), store.dispatch)
  })
  acting = false
})