import { Button, Icon, Icons } from 'components/elements';
import { Navbar, NavbarBrand, NavbarDropdown, NavbarEnd, NavbarItem, NavbarMenu, NavbarStart } from 'components/navbar';
import { Color, Size } from 'components/style';
import { h } from "preact";

interface Props {
    logout: () => void;
    outlets: Array<{id: number, name:string}>;
    setOutlet: (id:number) => void;
}

const Header: preact.FunctionalComponent<Props> = (props) => {
    return (
        <Navbar>
            <NavbarBrand burger={true}>
                <NavbarItem img='/assets/sensact-logo.png'/>
            </NavbarBrand>
            <NavbarMenu>
                <NavbarStart>
                    <NavbarDropdown label="Motorvärmare" isHoverable={true}>
                        {props.outlets.map(e => (<NavbarItem label={e.name} onClick={() => (props.setOutlet(e.id))} />)) }
                    </NavbarDropdown>
                </NavbarStart>
                <NavbarEnd>
                    <NavbarItem>
                        <Button color={Color.danger} isOutlined={true} onClick={props.logout}>
                            <Icon icon={Icons.signOut}/>
                            <span>Logga ut</span>
                        </Button>
                    </NavbarItem>
                </NavbarEnd>
            </NavbarMenu>
        </Navbar>
    );
};

export default Header;
