import { createRef, h, RefObject } from 'preact';

import "bulma-extensions/bulma-calendar/dist/css/bulma-calendar.min.css";
import bulmaCalendar from 'bulma-extensions/bulma-calendar/src/js';

import { Button, Buttons, Icon, Icons, Tag, Tags } from 'components/elements';
import { Color, Size } from 'components/style';
import * as style from 'components/style.scss';
import { useEffect, useState } from 'preact/hooks';
import { connect } from 'react-redux';
import { Field, formValueSelector, InjectedFormProps, reduxForm, reset } from 'redux-form';
import { ScheduleDataJSON, Weekday, WeekdayTag } from './data';

interface Props extends InjectedFormProps<{}, {}, string> {
    data?: any;
    onCancel: () => void;
    repeatValue: boolean;
    resetForm: () => void;
    onSubmit?: (arg0: any) => void;
}
interface TimeRefObject extends RefObject<any> {
    onChange: (data: any) => any;
    onFocus: () => any;
    onBlur: () => any;
}
const renderTime = (ref: TimeRefObject, field: any) => {
    ref.onChange = field.input.onChange;
    ref.onFocus = field.input.onFocus;
    ref.onBlur = field.input.onBlur;
    return (<div type='time' {...field.input} ref={ref} />);
}

const required = (value:any) => value ? undefined : 'Du måste ange minst 1 dag'

const renderDays = (repeatValue: boolean, field: any) => {
    if (repeatValue !== undefined && !repeatValue && Array.isArray(field.input.value) && field.input.value.length > 1 ) {
        field.input.onChange([]);
    }
    return (
        <div class={style.field}>
            <label class={style.label}>{repeatValue ? 'Starta följande dagar:' : 'Starta denna dag:'} </label>
            <div class={style.control}>
                <Buttons hasAddons={true}>
                {
                    WeekdayTag.map((e, i) => {
                        return <Button label={e.tag}
                                color={Array.isArray(field.input.value) && field.input.value.includes(e.day) ? Color.success : Color.light}
                                isRounded={true}
                                key={i}
                                size={Size.small}
                                onMouseDown={() => {
                                    let days = [];
                                    if (Array.isArray(field.input.value)) {
                                        days = field.input.value.slice(0);
                                    }
                                    if (repeatValue) {
                                        if (days.includes(e.day)) {
                                            days.splice( days.indexOf(e.day), 1 );
                                            field.input.onChange(days);
                                        } else {
                                            days.push(e.day);
                                            field.input.onChange(days);
                                        }
                                    } else {
                                        field.input.onChange([e.day]);
                                    }
                                }}
                                />
                            })
                }
                </Buttons>
            </div>
            {field.meta.error && <p class={style.help + ' ' + style.isDanger}>{field.meta.error}</p>}
        </div>

    );
}

let ScheduleEntryForm: preact.FunctionalComponent<Props> = (props) => {
    const timeRef = createRef() as TimeRefObject;
    const [mounted, setMounted] = useState(false);
    const [timepicker, setTimepicker] = useState(undefined);

    useEffect(() => {
        if (timeRef.current === null) {
            return;
        }
        if (mounted) {
            return;
        }
        let start = null;
        if (props.data && props.data.time) {
            const today = new Date();
            start = new Date(today.getFullYear(), today.getMonth(), today.getDate(), props.data.time.hour, props.data.time.minute, 0);
        } else {
            const today = new Date();
            start = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 12, 0, 0);
        }
        const timepickers = bulmaCalendar.attach(timeRef.current /*'[type="time"]'*/, { start, displayMode: 'inline', showButtons: false, showClearButton: false, showFooter: false });

        const next = timepickers[0]._ui.container.querySelectorAll('.timepicker-next');
        next.forEach((btn:any) => {
            btn.addEventListener('click', (e:any) => {
                timeRef.onChange({
                    hour: timepickers[0].time.start.getHours(),
                    minute: timepickers[0].time.start.getMinutes()
                });
            });
        });

        const prev = timepickers[0]._ui.container.querySelectorAll('.timepicker-previous');
        prev.forEach((btn:any) => {
            btn.addEventListener('click', (e:any) => {
                timeRef.onChange({
                    hour: timepickers[0].time.start.getHours(),
                    minute: timepickers[0].time.start.getMinutes()
                });
            });
        });

        setTimepicker(timepickers[0]);
        setMounted(true);
    });

    return (
        <div>
            {/* 
            // @ts-ignore */}
            <form onSubmit={props.handleSubmit} >
                <div class={style.field}>
                    <label class={style.label}>Tid</label>
                    <div class={style.control}>
                        <Field component={(field: any) => (renderTime(timeRef, field))} name="time" />
                    </div>
                </div>

                <div class={style.field}>
                    <Field component="input" class={style.isCheckradio} id="repeat" type="checkbox" name="repeat" />
                    <label for="repeat"> <Icon icon={Icons.redo} size={Size.small} iconSize='sm' /> Återkommande</label>
                </div>

                <Field component={(field: any) => (renderDays(props.repeatValue, field))} name="days"
                    validate={required}/>
                <div class={style.field + ' ' + style.isGrouped + ' ' + style.mTLg}>
                    <div class={style.control}>
                        <Button type='submit' color={Color.primary}>Spara</Button>
                    </div>
                    <div class={style.control}>
                        <Button
                            onClick={() => {
                            props.resetForm();
                            if(timepicker) {
                                (timepicker as any).clear();
                            }
                            props.onCancel();
                        }}>Avbryt</Button>
                    </div>
                </div>
            </form>
        </div>
    );
}

// @ts-ignore
ScheduleEntryForm = reduxForm({ form: 'scheduleEntry' })(ScheduleEntryForm);
const formSelector = formValueSelector('scheduleEntry');

// @ts-ignore
ScheduleEntryForm = connect((state, props : Props) => {
    return {
        initialValues: props.data ? props.data : {time:{hour: 12, minute: 0}},
        repeatValue: formSelector(state, 'repeat')
    }
},
(dispatch, props) => {
    return {
        resetForm: () => (dispatch(reset('scheduleEntry')))
    };
}
// @ts-ignore
)(ScheduleEntryForm);
export default ScheduleEntryForm;