import { h, VNode } from 'preact';

import { checkHasProperties, HasClass, HasIsCentered, HasIsMobile, HasOffset, HasSize, HasTextCentered } from 'components/style';
import * as style from 'components/style.scss';

interface ColumnProps extends HasTextCentered, HasSize, HasOffset, HasClass {
    children: any | any[];
    id?:string;
}

export const Column: preact.FunctionalComponent<ColumnProps> = ( props ) => {
    return (
        <div class={checkHasProperties(props, style.column)} id={props.id}>
            { props.children }
        </div>
    )
}

interface ColumnsProps extends HasIsMobile, HasIsCentered {
    children: VNode<typeof Column> | Array<VNode<typeof Column>>;
}

export const Columns : preact.FunctionalComponent<ColumnsProps> = ( props ) => {
    return (
        <div class={checkHasProperties(props, style.columns)}>
            { props.children }
        </div>
    )
}