import * as style from 'components/style.scss';
import { h, VNode } from 'preact';
import NavbarItem from './NavbarItem';
import { NavbarStart } from './NavbarStartEnd';
import { NavbarEnd } from './NavbarStartEnd';

interface Props {
    isActive?: boolean;
    children: VNode<typeof NavbarStart> | VNode<typeof NavbarEnd> | [VNode<typeof NavbarStart>, VNode<typeof NavbarEnd> ]; 
}

const NavbarMenu : preact.FunctionalComponent<Props> = (props) => {
    return (
        <div class={style.navbarMenu + (props.isActive ? ' ' + style.isActive : '')}>
            {props.children}
        </div>
    );
}

export default NavbarMenu;