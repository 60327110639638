import { graphqlOperation } from '@aws-amplify/api';
import API from '@aws-amplify/api';
import { show as toastShow } from 'utils/toast';
import { createScheduleEntry, deleteScheduleEntry, updateOutlet, updateScheduleEntry } from '../../graphql/mutations';
import { getOutlet, listOutlets,  } from '../../graphql/queries';

const PREFIX = 'OUTLETS';

const updateState = (state:any, updatedValues:any) => {
    return Object.assign({}, state, updatedValues);
}

const fragment = (prefix: string) => {
    return (name: string, handler:any) => (
        (params?:any) => (
            {
                type: prefix + '_' + name,
                handler,
                ...params,
            }
        )
    )
}

const fragmentReducer = (prefix: string, defState:any) => {
    return (state: any = defState, action:any) => {    
        if ('handler' in action && action.type.startsWith(prefix + '_')) {
            return action.handler(state, action);
        }
        return state;
    }
}


const outletsFragment = fragment(PREFIX);

/******************* FETCH OUTLETS *******************/
const outletsFetch = outletsFragment('FETCH', (state:any, action:any): any => {
    if (state.isFetching) {
        return state;
    }
    API.graphql(
        graphqlOperation(listOutlets, {})
    )
        // @ts-ignore
        .then((data:any) => {
            action.asyncDispatch(outletsFetched(data.data.listOutlets.items));
        })
        .catch((error:any) => {
            console.log(error);
            action.asyncDispatch(outletsError('pull'));
        });
    return updateState(state, { isFetching: true });
});

const outletsFetchedFragment = outletsFragment('FETCHED', (state:any, action:any): any => {
    return updateState(defaultState, { outlets: action.outlets.sort((a:any, b:any) => (a.name.localeCompare(b.name))), updated: true });
});

const outletsFetched = (outlets:any) => (outletsFetchedFragment({outlets}));

/****************** FETCH OUTLET ********************/
const outletFetchFragment = outletsFragment('OUTLET_FETCH', (state:any, action:any) => {
    API.graphql(
        graphqlOperation(getOutlet, {id: action.id})
    )
        // @ts-ignore
        .then((data:any) => {
            action.asyncDispatch(outletFetched(data.data.getOutlet));
        })
        .catch((error:any) => {
            action.asyncDispatch(outletsError('pull'));
        });
    return Object.assign({}, state, {isFetching: true });
});
const outletFetch = (id:string) => (outletFetchFragment({id}));


const outletFetchedFragment = outletsFragment('OUTLET_FETCHED', (state:any, action:any) => {
    let items = [];
    
    if (action.outlet.schedule.items) {
        items = action.outlet.schedule.items.map((s:any) => {
            return {...s, outlet: action.outlet};
        })
    }


    let outlets = state.outlets.filter((e:any) => (e.id !== action.outlet.id));
    outlets = [{...action.outlet, schedule:{items}}, ...outlets].sort((a, b) => (a.name.localeCompare(b.name)));

    if (action.outlet.activate === true && state.activate === false) {
        action.asyncDispatch(outletActivate(action.outlet.id));
    }

    return updateState(defaultState, {outlets, updated: true, activate: action.outlet.activate});
});

const outletFetched = (outlet:any) => ( outletFetchedFragment({outlet}) );

/******************* ACTIVATE OUTLET *******************/
const outletActivateFragment = outletsFragment('OUTLET_ACTIVATE', (state:any, action:any) => {
    const outlets = state.outlets.map((e:any) => {
        if (e.id === action.id) {
            const fetch = () => {
                API.graphql(
                    graphqlOperation(getOutlet, {id: action.id})
                )
                    // @ts-ignore
                    .then((d2:any) => {
                        if (d2.data.getOutlet.active !== true) {
                            setTimeout(fetch, 4000);
                        }
                        action.asyncDispatch(outletFetched(d2.data.getOutlet));
                    })
                    .catch((e2:any) => {
                        action.asyncDispatch(outletFetched({...e, activate: false}));
                        action.asyncDispatch(outletsError('pull'));
                    });
            };
            if (e.activate === false) {
                API.graphql(graphqlOperation(updateOutlet,{
                    input: {
                        id: e.id,
                        activate: true
                    }
                }))
                    // @ts-ignore
                    .then((d:any) => {
                        action.asyncDispatch(outletFetched(d.data.updateOutlet));
                        setTimeout(fetch, 4000);
                    })
                    .catch( (e2:any) => {
                        action.asyncDispatch(outletFetched({...e, activate: false}));
                        action.asyncDispatch(outletsError('push'));
                    });
            } else {
                setTimeout(fetch, 4000);
            }
            return {...e, activate: true};
        }
        return e;
    })
    return updateState(state, {outlets});
});

const outletActivate = (id:string) => (outletActivateFragment({id}));


/******************* SCHEDULE ADD *******************/
const outletScheduleAddFragment = outletsFragment('SCHEDULE_ADD', (state:any, action:any) => {
    API.graphql(graphqlOperation(createScheduleEntry,{
        input: action.data
    }))
        // @ts-ignore
        .then((data:any) => {
            action.asyncDispatch(outletScheduleUpdated(data.data.createScheduleEntry));
        })
        .catch( (error:any) => {
            console.log(error);
            action.asyncDispatch(outletsError('push'));
        });
    return updateState(state, { isFetching: true });
});
const outletScheduleAdd = (data:any) => (outletScheduleAddFragment({data}));

/******************* SCHEDULE DELETE *******************/
const outletScheduleDeleteFragment = outletsFragment('SCHEDULE_DELETE', (state:any, action:any) => {
    API.graphql(graphqlOperation(deleteScheduleEntry,{
        input: {
            id: action.schedule.id,
            _version: action.schedule._version
        }
    }))
        // @ts-ignore
        .then((data:any) => {
            action.asyncDispatch(outletScheduleDeleted(data.data.deleteScheduleEntry));
        })
        .catch( (error:any) => {
            action.asyncDispatch(outletsError('push'));
        });
    return updateState(state, {isFetching: true });
})
const outletScheduleDelete = (schedule:any) => (outletScheduleDeleteFragment({schedule}));

const outletScheduleDeletedFragment = outletsFragment('SCHEDULE_DELETED', (state:any, action:any) => {
    const outlets = state.outlets.map((e:any) => {
        if (e.id === action.schedule.outlet.id) {
            return {
                ...e,
                schedule: {
                    items: e.schedule.items.filter((schedule:any) => {
                        if (schedule.id === action.schedule.id) {
                            return false;
                        }
                        return true;
                    })
                }
            }
        }
        return e;
    })
    return Object.assign({}, state, {outlets, isFetching: false});
})
const outletScheduleDeleted = (schedule:any) => (outletScheduleDeletedFragment({schedule}));


/******************* SCHEDULE UPDATE *******************/
const outletScheduleUpdateFragment = outletsFragment('SCHEDULE_UPDATE', (state:any, action:any) => {
    API.graphql(graphqlOperation(updateScheduleEntry,{
        input: {
            id: action.schedule.id,
            _version: action.schedule._version,
            ...action.data
        }
    }))
        // @ts-ignore
        .then((data:any) => {
            action.asyncDispatch(outletScheduleUpdated(data.data.updateScheduleEntry));
        })
        .catch( (error:any) => {
            action.asyncDispatch(outletsError('push'));
        });
    return updateState(state, {isFetching: true });
})
const outletScheduleUpdate = (schedule:any, data:any) => (outletScheduleUpdateFragment({schedule, data}));

const outletScheduleUpdatedFragment = outletsFragment('SCHEDULE_UPDATED', (state:any, action:any) => {
    const outlets = state.outlets.map((e:any) => {
        if (e.id === action.schedule.outlet.id) {
            // Filter out shedule if it exists
            const items = e.schedule.items.filter((schedule:any) => {
                if (schedule.id === action.schedule.id) {
                    return false;
                }
                return true;
            });
            return {
                ...e,
                schedule: {items: [...items, action.schedule]}
            }
        }
        return e;
    })

    return updateState(state, {outlets, isFetching: false });
})
const outletScheduleUpdated = (schedule:any) => (outletScheduleUpdatedFragment({schedule}));

/******************* SCHEDULE SET ENABLED *******************/
const outletScheduleSetEnabledFragment = outletsFragment('SCHEDULE_SET_ENABLED', (state:any, action:any) => {
    action.asyncDispatch(outletScheduleUpdated(Object.assign({}, action.schedule, {active:action.enabled})));
    API.graphql(graphqlOperation(updateScheduleEntry,{
        input: {
            id: action.schedule.id,
            _version: action.schedule._version,
            active: action.enabled
        }
    }))
        // @ts-ignore
        .then((data:any) => {
            action.asyncDispatch(outletScheduleUpdated(data.data.updateScheduleEntry));
        })
        .catch( (error:any) => {
            console.log(action.schedule);
            action.asyncDispatch(outletScheduleUpdated(Object.assign({}, action.schedule, {active:!action.enabled})));
            action.asyncDispatch(outletsError('push'));
        });
    return state;
})
const outletScheduleSetEnabled = (schedule:any, enabled:boolean) => (outletScheduleSetEnabledFragment({schedule, enabled}));

/******************* ERROR *******************/
const outletsErrorFragment = outletsFragment('ERROR', (state: any, action:any) => {
    switch (action.error) {
    case 'pull':
        toastShow({type: 'danger', title: 'Något gick fel när information skulle hämtas, försök igen senare...', message: '', duration: 10000});
        break;
    case 'push':
        toastShow({type: 'danger', title: 'Något gick fel när information skulle skickas, försök igen senare...', message: '', duration: 10000});
        break;
    }
    return updateState(state, {isFetching: false, updated: true});
})

type ScheduleError = 'pull' | 'push';

const outletsError = (error:ScheduleError) => (outletsErrorFragment({error}));

/******************* Clear ******************/
const outletsClear = outletsFragment('CLEAR', (state: any, action:any) => {
    return Object.assign({}, defaultState);
})

/******************* Reducer *******************/
/* Default State */
const defaultState = { outlets: [], isFetching: false, updated: false, activate: false };
const outletsReducer = fragmentReducer(PREFIX, defaultState);

export {
    outletsReducer,
    outletsFetch,
    outletsFetched,
    outletFetch,
    outletFetched,
    outletActivate,
    outletScheduleAdd,
    outletScheduleDelete,
    outletScheduleUpdate,
    outletScheduleSetEnabled,
    outletsClear,
}