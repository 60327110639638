import "./style/index.css";
import "./style/animate.min.css";
import "bulma/css/bulma.min.css";
import App from "./components/app.tsx";

import awsconfig from "./aws-exports";
import API from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";

import * as Toast from "utils/toast";

//Amplify.Logger.LOG_LEVEL = 'DEBUG';

window.global = window;
// @ts-ignore
window.Buffer = window.Buffer || require("buffer").Buffer;

Auth.configure(awsconfig);
API.configure(awsconfig);

Toast.init();

export default App;
