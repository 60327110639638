export interface ScheduleDataJSON {
    hour:number,
    minute:number,
    repeat: boolean,
    active: boolean
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
}
export interface ScheduleJSON {
    id: number,
    active: boolean,
    repeat: boolean,
    time: {
        hour: number,
        minute: number
    },
    days: {
        monday: boolean,
        tuesday: boolean,
        wednesday: boolean,
        thursday: boolean,
        friday: boolean,
        saturday: boolean,
        sunday: boolean,
    }
}

export type Weekday = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export const WeekdayTag: Array<{day: Weekday, tag: string}> = [
    {day: 'monday', tag: 'M'},
    {day: 'tuesday', tag: 'T'},
    {day: 'wednesday', tag: 'O'},
    {day: 'thursday', tag: 'T'},
    {day: 'friday', tag: 'F'},
    {day: 'saturday', tag: 'L'},
    {day: 'sunday', tag: 'S'}
];

export const TestData : ScheduleJSON[] = [
    {
        "id": 1,
        "time": {
            "hour": 8,
            "minute": 30
        },
        "repeat": true,
        "days": {
            "monday": true,
            "tuesday": true,
            "wednesday": true,
            "thursday": true,
            "friday": true,
            "saturday": true,
            "sunday": true
        },
        "active": true
    },
    {
        "id": 2,
        "time": {
            "hour": 21,
            "minute": 8
        },
        "repeat": true,
        "days": {
            "monday": true,
            "tuesday": false,
            "wednesday": false,
            "thursday": false,
            "friday": false,
            "saturday": true,
            "sunday": false
        },
        "active": false
    },
    {
        "id": 3,
        "time": {
            "hour": 12,
            "minute": 0
        },
        "repeat": false,
        "days": {
            "monday": false,
            "tuesday": true,
            "wednesday": false,
            "thursday": false,
            "friday": false,
            "saturday": false,
            "sunday": false
        },
        "active": true
    }
]